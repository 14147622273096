import emojiRegex from 'emoji-regex';
import { camelCase, get, isArray, toString, isObject, trim, isNil, isEmpty } from 'lodash';
import { format } from 'redux-form-validators';
import { getCurrentMember } from './storage';

export function cleanInput(value, removeEmoji = true) {
    if (!value) {
        return "";
    }
    if (removeEmoji === true) {
        const emojis = emojiRegex();
        return value.toString().replace(emojis, "");
    }
    return value;
}

export function cleanFormValues(values, beforeSend = false) {
    // Manipulate values if beforeSend is not false
    const newValues = beforeSend !== false ? beforeSend(values) : values;

    // Clone object
    let data = Object.assign({}, newValues);

    // Convert all empty string to null
    Object.entries(data).map(([k, v]) => {
        if (trim(v) === "") {
            data[k] = null;
            return true;
        }
        return false;
    })

    if (data.location && isObject(data.location)) {
        // Convert child to string && update the "" from location object as API doesn't support it
        Object.entries(data.location).map(([k, v]) => {
            if (trim(v) === "") {
                data["location"][k] = null;
                return true;
            }
            data["location"][k] = toString(v);
            return false;
        })
    }

    return data;
}

export function transformErrors(error) {
    let serverError = ["Error in contacting API. Please try in 10 minutes"];
    const errors = get(error, "response.data", false);
    if (isArray(errors)) {
        return errors
    }
    if (get(errors, 'type') && ['yii\\web\\HttpException', 'yii\\web\\NotFoundHttpException'].includes(get(errors, 'type'))) {
        return [get(errors, 'message')];
    }
    if (errors !== false) {
        // Convert error key to camelcase to sync with a field
        let newErrors = {};
        Object.entries(errors).map(([key, value]) => {
            const fieldName = get(key.split('-'), `1`, key);
            newErrors[camelCase(fieldName)] = isArray(value) ? value[0] : value;
            return true;
        })
        serverError = newErrors;
    }
    if (errors === false && process.env.REACT_APP_MODE !== "prod" && error.message) {
        serverError = [error.message];
    }
    return serverError;
}

export function nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export const getPasswordValidationRegex = () => {
    return format({ with: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*\W).{7,}\S+$/, message: 'Should contain: [A-Z] [a-z] [0-9] and a symbol. No space.' });
}

export const maskEmail = (email) => {
    if (!email) {
        return "";
    }
    // Split email address into username and domain parts
    const [username, domain] = email.split('@');

    // Replace some characters in the username with asterisks
    const maskedUsername = username.substring(0, Math.min(username.length, 3)) + '***';

    // Replace some characters in the domain with asterisks
    const maskedDomain = domain.substring(0, Math.min(domain.length, 3)) + '***';

    // Construct the masked email
    const maskedEmail = `${maskedUsername}@${maskedDomain}`;

    return maskedEmail;
}

export const generateTimeSlots = () => {
    const timeSlots = [];
    for (let hour = 6; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const amPmHour = hour % 12 || 12; // Convert hour to 12-hour format
            const amPm = hour < 12 ? 'AM' : 'PM'; // Determine AM or PM
            const formattedHour = String(amPmHour).padStart(2, '0');
            const formattedMinute = String(minute).padStart(2, '0');
            const timeString = `${formattedHour}:${formattedMinute} ${amPm}`;
            timeSlots[timeString] = timeString;
        }
    }
    return timeSlots;
}

export const addHoursToTime = (time, hoursToAdd = 3) => {
    // Determine if time is in AM or PM and split it into hours and minutes
    const isPM = time.toLowerCase().includes('pm');
    const [timePart] = time.toLowerCase().replace(' am', '').replace(' pm', '').split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);

    // Convert hours to 24-hour format if necessary
    if (isPM && hours < 12) {
        hours += 12;
    } else if (!isPM && hours === 12) {
        hours = 0;
    }

    // Add the specified hours to the extracted hours
    let newHours = hours + hoursToAdd;

    // Handle overflow if new hours exceed 24
    if (newHours >= 24) {
        newHours -= 24;
    }

    // Convert newHours back to 12-hour format and determine new period (AM/PM)
    const newPeriod = newHours >= 12 ? 'PM' : 'AM';
    if (newHours > 12) {
        newHours -= 12;
    } else if (newHours === 0) {
        newHours = 12;
    }

    // Format the new hours and minutes to ensure they are two digits
    const formattedHours = String(newHours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${newPeriod}`;
}

export const makeSearchAvailabilityParams = (availability) => {
    return {
        "restaurant": get(availability, "restaurant"),
        "end_time": getDiningEndTime(get(availability, 'dining_type')),
        "date": get(availability, "date"),
        "party_size": get(availability, "party_size"),
        "type": get(availability, "type"),
        "member_id": getCurrentMember(),
        "start_time": getDiningStartTime(get(availability, 'dining_type')),
        "dining_type": get(availability, 'dining_type'),
    }
};

export function isValidValue(value) {
    return !isNil(value) && !isEmpty(value) && value !== "null" && value !== "NULL";
}

export const semicolonStrToArr = (string) => {
    return isValidValue(string) ? string.split(";") : []
}

export const getNotificationOptions = () => {
    return [
        {
            "id": 'tickets-remainders',
            "text": "Event Tickets & Reminders",
        },
        {
            "id": 'membership-renewals',
            "text": "Membership Actions and Renewal",
        },
        {
            "id": "restaurant-reservations",
            "text": "Restaurant and Bar Reservations",
        },
        {
            "id": "bills-statements-transactions",
            "text": "Bills, Statements and Transactions",
        },
    ];
}

export const getPAAccessOptions = () => {
    return [
        {
            "id": 'manage-events',
            "text": "Booking and Managing Events",
        },
        {
            "id": 'manage-reservations',
            "text": "Restaurant and Bar Reservations",
        },
        // {
        //     "id": "manage-memberships",
        //     "text": "Membership Actions and Renewal",
        // },
        {
            "id": "manage-bills-statements-transactions",
            "text": "Bills, Statements and Transactions",
        },
    ];
}

export const getBillType = (type) => {
    const billTypes = {
        'event-deposit': "Event Deposit",
        'balance-payment': "Balance Payment",
        'event-tickets': "Event Tickets"
    }

    return get(billTypes, type, '');
}

export const getDietaryTags = () => {
    return {
        "Dietary restrictions": ["Alcohol-free", "Allergy", "Allium", "Crustacean", "Dairy-free", "Diabetic", "Eggs", "Fish", "Garlic", "Gluten-free", "Hazelnut", "Kosher", "Lactose Intolerant", "Milk", "Mushrooms", "Nightshade", "Nuts", "Paleo", "Peanuts", "Pescatarian", "Pork", "Poultry", "Pregnant", "Red meat", "Salt", "Seafood", "Shellfish", "Shrimp", "Soy", "Sulfites", "Tomatoes", "Tree nuts", "Vegan", "Vegetarian", "Walnuts", "Wheat"],
        // "Seating Preference": ["Bar area", "Booth", "Corner", "Downstairs", "Indoors", "Kitchen", "Main room", "Outdoors", "PDR", "Patio", "Quiet table", "Round table", "Smoking area", "Table Only", "Table with a view", "Upstairs", "Wheelchair access", "Window"],
        // "Seating Request": ["Bar area requested", "Booth requested", "Corner requested", "Downstairs requested", "Indoors requested", "Kitchen requested", "Main room requested", "Outdoors requested", "PDR", "Patio requested", "Quiet table requested", "Round table requested", "Smoking area requested", "Table request", "Upstairs requested", "Wheelchair access", "Window requested", "Table with a view requested"],
        // "Special Attention": ["Alert Manager", "Alert chef", "House account", "Non-English speaking", "Wheelchair", "Alert GM", "Booster Seat", "First time diner", "Highchair", "Separate checks"],
        // "Special Occasions": ["Anniversary", "Baby shower", "Bachelor", "Bachelorette", "Birthday", "Bridal shower", "Business celebration", "Business meeting", "Celebration", "Congratulations", "Corporate event", "Engagement", "Female birthday", "Graduation", "Honeymoon", "Male birthday", "Post-theater", "Pre-theater", "Special event", "Work Promotion"],
        // "Wine": ["Argentinian", "BYOB", "Barbaresco", "Barolo", "Bordeaux", "Cabernet Franc", "Cabernet Sauvignon", "California", "Cava", "Chardonnay", "Chenin Blanc", "Chianti", "Dessert Wine", "Dry", "Earthy", "French", "Fruity", "Full body", "Gluten-free wine", "Grenache", "Italian", "Kosher wine", "Large Format Wine", "Malbec", "Medium", "Merlot", "Montepulciano", "Moscato", "Mourvèdre", "Muscat", "Napa Valley", "Nebbiolo", "No Wine", "Organic wine", "Petit Verdot", "Petite Sirah", "Pinot Blanc", "Pinot Grigio", "Pinot Gris", "Pinot Meunier", "Pinot Noir", "Port", "Prosecco", "Red", "Riesling", "Rosé", "Sancerre", "Sangiovese", "Sauvignon Blanc", "Sherry", "Shiraz", "Spanish", "Syrah", "Tempranillo", "Vegan wine", "White", "Wine", "Wine $", "Wine $$", "Wine $$$", "Wine $$$$", "Wine lover", "Zinfandel"],
        // "Champagne": ["Armand de Brignac", "Barons De Rothschild", "Blanc de Blancs", "Blanc de Noirs", "Bollinger", "Brut", "Champagne", "Demi-Sec", "Dom Pérignon", "Doux", "Extra Brut", "Extra-Dry", "G.H. Mumm", "Krug", "Laurent-Perrier", "Louis Roederer", "Moët & Chandon", "Nicolas Feuillatte", "Perrier-Jouët", "Pommery", "Rosé", "Sec", "Taittinger", "Veuve Clicquot"],
        // "Comps": ["Comp appetizer", "Comp champagne", "Comp dessert", "Full Comp"],
        // "Spirits": ["1800", "42 Below", "Absinthe", "Absolut", "Appleton Estate", "Avion", "Bacardi", "Beefeater", "Belvedere", "Blue Nectar", "Bombay", "Brandy", "Bulleit", "Captain Morgan", "Casamigos", "Ciroc", "Clase Azul", "Cognac", "Courvoisier", "Crown Royal", "D'USSE", "DeLeon", "Dewar's", "Don Julio", "Don Q", "Finlandia", "Gin", "Glenfiddich", "Glenlivet", "Grey Goose", "Hendrick's", "Hennessy", "Herradura", "Jack Daniel's", "Jameson", "Jim Beam", "Johnnie Walker", "Jose Cuervo", "Jura", "Ketel One", "Knob Creek", "Laphroaig", "Liquer", "Louis XIII", "Macallan", "Maker's Mark", "Malibu", "Mezcal", "Milagro", "No Alcohol", "Patron", "Remy Martin", "Ron Zacapa", "Rum", "Russian Standard", "Sake", "Sauza", "Scotch", "Shellback", "Skyy", "Spring 44", "Stoli", "Svedka", "Tanqueray", "Tequila", "Three Olives", "Ultimat", "Vodka", "Whiskey", "Woodford Reserve"],
        // "Client Type": ["Always accommodate", "Blogger", "Concierge", "Employee", "Friend of Manager", "Friend of chef", "Friend of employee", "Friend of owner", "Friends & family", "Industry", "Influencer", "Press", "Regular"],
        // "Profession": ["Fashion", "Film", "Finance", "Government", "Hospitality", "Media", "PR", "Real Estate", "Retail", "Tech"],
        // "Source": ["Amex Concierge", "Executive Assistant", "Hotel Concierge", "Source"],
        // "VIP client type (VIP)": ["Celebrity", "Critic", "Investor", "VIP"],
        // "Water": ["Complimentary sparkling", "Complimentary still", "Pelligrino", "Sparkling water", "Still water", "Tap water"],
        // "Hot Reservations": ["Hot Reservation"],
        // "Private Event": ["Private Event"],
        // "Blacklist": ["Blacklist"],
        // "Promo": ["Promo"]
    }
}

export const getDiningTypes = (types) => {
    const diningTypes = [];
    if (get(types, 'breakfast') === 1) {
        diningTypes['breakfast'] = 'Breakfast';
    }
    if (get(types, 'lunch') === 1) {
        diningTypes['lunch'] = 'Lunch';
    }
    if (get(types, 'dinner') === 1) {
        diningTypes['dinner'] = 'Dinner';
    }
    return diningTypes;
}

export const getDiningStartTime = (type) => {
    const timings = {
        'breakfast': '7:00',
        'lunch': '12:00',
        'dinner': '18:00'
    };
    return get(timings, type);
}

export const getDiningEndTime = (type) => {
    const timings = {
        'breakfast': '10:30',
        'lunch': '15:30',
        'dinner': '23:30'
    };
    return get(timings, type);
}

export const createSlug = (text) => {
    return text.toLowerCase().trim().replace(/[\s\W-]+/g, '-').replace(/^-+|-+$/g, '');
}

export const getRefundStatus = (status) => {
    const statuses = {
        "1": 'Refund Started',
        "2": 'Refund Processed',
        "3": 'Refund Failed',
        "4": 'Refund Reversed',
    };
    return get(statuses, status);
}