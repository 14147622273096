import React, { useState } from "react";
import style from './style.module.scss';
import Booking from '../../../components/DialogBox/Booking';
import { Spacer, Text, Title } from "../../../components/Elements";
import AsyncForm from "../../../components/Materials/AsyncForm";
import { Button, Checkbox, Input, HiddenInput } from "../../../components/Field";
import { get } from "lodash";
import '@adyen/adyen-web/dist/adyen.css';
import { Field } from "redux-form";
import { numericality, required } from "redux-form-validators";
import { formatNumber } from "../../../helpers/format";
import { getCurrentClubCurrency, getCurrentClub, getCurrentMember, getUserClubAttr } from "../../../helpers/storage";
import PaymentCardForm from "./PaymentCardForm";
import { useSelector } from "react-redux";
import { getAmexRestrictionText } from "../../../helpers/adyen";

const TopupBalance = ({ onClose }) => {
    const [step, setStep] = useState(1);
    const [paymentMethod, setPaymentMethod] = useState({});
    const [load, setLoad] = useState('init');
    const [formVal, setFormVal] = useState({});
    const { salesforce_meta = {} } = useSelector((state) => get(state, 'storage.data', {}));
    const AMEX__c = getUserClubAttr(salesforce_meta, 'AMEX__c');
    return (
        <Booking
            title="Balance payment"
            scroll={false}
            heightTrigger={[step, load]}
            content={(onDialogClose) => {
                return (
                    <>
                        {step === 1 &&
                            <>
                                <div className={style.info}>
                                    <Title text="To topup the amount to your account, enter the needed amount in the below box" fontSize="f24" animationDisabled={true} />
                                </div>
                                <AsyncForm
                                    url={`/payment/get-stored-cards`}
                                    label=""
                                    id="TopupBalance"
                                    type="get"
                                    title={false}
                                    permissionText="Payment Get Stored Cards"
                                    fixedSaveButton={false}
                                    showToastOnSuccess={false}
                                    submitButton={false}
                                    hasGetAction={false}
                                    onSave={({ data, formValues }) => {
                                        setStep(2);
                                        setPaymentMethod(data);
                                        setFormVal({
                                            amount: get(formValues, 'amount'),
                                            payment_type: get(formValues, 'payment_type'),
                                        });
                                    }}
                                    paramsData={{
                                        club: getCurrentClub(),
                                        member_id: getCurrentMember(),
                                    }}
                                    render={({ fetching, formValues, invalid, change }) => {
                                        return (
                                            <>
                                                <div className={`${style.wrap} ${style.additionWrab}`}>
                                                    <div className={style.amountBox}>
                                                        <div className={style.fieldWrap}>
                                                            <div className={style.label}><Text text={`Enter Amount(${getCurrentClubCurrency()}) *`} animationDisabled={true} /></div>
                                                            <div className={`${style.field} ${style.full}`}>
                                                                <Field component={Input} name="amount" validate={[required(), numericality({ '>': 0, })]} animationDisabled={true} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className={style.paymentBox}>
                                                        <Title text="Pay for" fontSize="f24" animationDisabled={true} />
                                                        <Spacer />
                                                        <Field component={Checkbox} name="bal" label="Balance payment" animationDisabled={true}
                                                            onChange={(val) => {
                                                                change('payment_type', val)
                                                            }}
                                                            onValue={'balance-payment'}
                                                            checked={get(formValues, 'payment_type') === 'balance-payment'}
                                                        />
                                                        <Spacer />
                                                        <Field component={Checkbox} name="even" label="Event Deposit" animationDisabled={true}
                                                            onChange={(val) => {
                                                                change('payment_type', val)
                                                            }}
                                                            onValue={'event-deposit'}
                                                            checked={get(formValues, 'payment_type') === 'event-deposit'}
                                                        />
                                                        <Field component={HiddenInput} name="payment_type" validate={[required()]} />
                                                    </div>
                                                </div>
                                                <div className={style.float}>
                                                    <div className={style.action}>
                                                        <div></div>
                                                        <Button label="Next" submit={true} disabled={!get(formValues, 'payment_type') || invalid} fetching={fetching} />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }}
                                />
                            </>
                        }
                        {step === 2 &&
                            <>
                                <div className={style.info}>
                                    <Title text={`Total amount to topup is: ${getCurrentClubCurrency()}${formatNumber(get(formVal, 'amount', 0))}`} fontSize="f24" animationDisabled={true} />
                                    {AMEX__c === false &&
                                        <>
                                            <Spacer size="s16" />
                                            <Text text={getAmexRestrictionText()} />
                                        </>
                                    }
                                </div>
                                <PaymentCardForm paymentMethod={paymentMethod} setLoad={setLoad} formVal={formVal} formData={{ payment_type: get(formVal, 'payment_type') }} onDialogClose={onDialogClose} setStep={setStep} toastMsg={get(formVal, 'payment_type') === "event-deposit" ? "Event Deposit successfull" : "Balance Payment successfull"} />
                            </>
                        }
                    </>
                )
            }}
            onClose={onClose}
        />
    )
}
export default TopupBalance;